<template>
  <footer class="footer">
    <p>
      All content on this website page, including but not limited to text,
      graphics, logos, images, audio clips, digital downloads, and data
      compilations, is the property of Rezcomm and is protected by copyright
      laws.
    </p>
    <p>
      All rights are reserved. Any unauthorized use, reproduction, or
      distribution of the content is strictly prohibited without the express
      written consent of Rezcomm.
    </p>
    <p>
      For more information on your permitted use of this website page, please
      contact <a href="mailto:support@rezcomm.com">support@rezcomm.com</a>.
    </p>
    <p>© Rezcomm - Airport Direct Travel</p>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped lang="scss">
.footer {
  padding: 0.5rem;
  text-align: center;
  background-color: #f8f9fa;
  border-top: 2px solid #e9ecef;
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
}

.footer p {
  margin: 10px 0;
  color: #6c757d;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
}

.footer a {
  color: #6c757d;
  text-decoration: underline;
}
</style>
