<template>
  <div class="main-container">
    <Header />
    <div class="content">
      <h1 class="gradient-heading">Welcome to Rezcomm AI Labs</h1>
      <div class="audio-recording">
        <Button @click="initiateSpeech" v-if="!isStarted"> Start </Button>
        <div v-if="isLoading">
          <ProgressSpinner
            style="width: 50px; height: 50px; margin-top: 56px"
            strokeWidth="8"
            fill="var(--surface-ground)"
            aria-label="Custom ProgressSpinner"
          />
        </div>
        <div
          v-else-if="isStarted"
          class="record-button-container"
          style="margin-top: 56px"
          @mousedown="handleStartRecording"
          @touchstart.prevent="handleStartRecording"
          @touchend.prevent="handleMouseUp"
          @mouseup="handleMouseUp"
          :class="{ recording: isRecording }"
        >
          <canvas ref="canvas" class="waveform"></canvas>
          <div
            class="record-button"
            :class="{ recording: isRecording, 'green-mic': isRecording }"
          >
            <i class="fas fa-microphone"></i>
          </div>
        </div>

        <Message v-if="isStarted" class="status-message" severity="info">
          Click the microphone to start talking and release it to stop.</Message
        >
      </div>
    </div>
    <Footer />
    <Toast ref="toast" style="max-width: calc(100% - 40px)" />
  </div>
</template>

<script>
import axios from "axios";
import Toast from "primevue/toast";
import Message from "primevue/message";
import ProgressSpinner from "primevue/progressspinner";
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
import "@fortawesome/fontawesome-free/css/all.css";
import Button from "primevue/button";

export default {
  name: "Index",
  components: {
    Toast,
    ProgressSpinner,
    Footer,
    Header,
    Button,
    Message,
  },
  data() {
    return {
      isRecording: false,
      mediaRecorder: null,
      audioChunks: [],
      audioUrl: null,
      visible: true,
      isLoading: false,
      audioContext: null,
      analyser: null,
      dataArray: null,
      animationFrameId: null,
      hasSpoken: false,
      isStarted: false,
      clickStart: null,
      audioFiles: [],
    };
  },
  methods: {
    async handleStartRecording() {
      this.clickStart = Date.now();
      await this.startRecording();
    },
    handleMouseUp() {
      const clickDuration = Date.now() - this.clickStart;
      if (clickDuration < 200) {
        // 200ms threshold for a click vs hold
        this.$refs.toast.add({
          severity: "warn",
          summary: "Warning",
          detail:
            "Please keep pressing the button to talk and release when done.",
          life: 3000,
        });
        this.isRecording = false;
      } else {
        this.stopRecording();
      }
      this.clickStart = null;
    },
    async initiateSpeech() {
      this.isStarted = true;
      this.$nextTick(() => {
        const canvas = this.$refs.canvas;
        if (canvas) {
          canvas.width = 120;
          canvas.height = 120;
        }
        this.playAudioSamples();
      });
    },
    async startRecording() {
      if (this.isRecording) return;
      this.$refs.toast.add({
        severity: "info",
        summary: "Info",
        detail: "Recording audio started",
        life: 3000,
      });

      if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
        alert("Your browser does not support audio recording.");
        return;
      }

      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });

        // Initialize audio context and analyser
        this.audioContext = new (window.AudioContext ||
          window.webkitAudioContext)();
        const source = this.audioContext.createMediaStreamSource(stream);
        this.analyser = this.audioContext.createAnalyser();
        this.analyser.fftSize = 256;
        source.connect(this.analyser);
        this.dataArray = new Uint8Array(this.analyser.frequencyBinCount);

        // Start media recorder
        this.mediaRecorder = new MediaRecorder(stream);
        this.audioChunks = [];
        this.mediaRecorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            this.audioChunks.push(event.data);
          }
        };
        this.mediaRecorder.onstop = this.handleStop;
        this.mediaRecorder.start();
        this.isRecording = true;

        // Ensure canvas size is set
        const canvas = this.$refs.canvas;
        canvas.width = 120;
        canvas.height = 120;

        // Start drawing waveform animation
        this.drawWaveform();
      } catch (error) {
        console.error("Error accessing audio device:", error);
      }
    },

    stopRecording() {
      if (this.mediaRecorder && this.isRecording) {
        this.mediaRecorder.stop();
        this.isRecording = false;
      }
      if (this.audioContext) {
        this.audioContext.close();
        this.audioContext = null;
        this.analyser = null;
        this.dataArray = null;
      }
      if (this.animationFrameId) {
        cancelAnimationFrame(this.animationFrameId);
        this.animationFrameId = null;
      }
    },
    async handleStop() {
      this.$refs.toast.add({
        severity: "info",
        summary: "Info",
        detail: "Verifying intent, please wait...",
        life: 3000,
      });
      this.isLoading = true;
      if (this.audioChunks.length === 0) {
        console.error("No audio data recorded.");
        this.isLoading = false;
        return;
      }

      const audioBlob = new Blob(this.audioChunks, { type: "audio/wav" });
      this.audioUrl = URL.createObjectURL(audioBlob);

      this.$nextTick(() => {
        const audioElement = this.$refs.audio;
        if (audioElement) {
          audioElement.src = this.audioUrl;
        }
      });

      const formData = new FormData();
      formData.append("file", audioBlob, "recording.wav");

      try {
        const response = await axios.post(
          "https://rezai.rezcommdev.com/intent/",
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );

        let result = response.data[1].replace(/\\/g, "").trim();
        result = result.replace(/'/g, '"');
        result = JSON.parse(result);
        console.log("Parsed result:", result);

        if (result.intent === "Login") {
          this.$refs.toast.add({
            severity: "success",
            summary: "Success",
            detail: "Intent recognized Successfully, Redirecting...",
            life: 3000,
          });
          this.hasSpoken = false;

          this.playAudio("success.mp3");

          setTimeout(() => {
            window.location.href = "/login.html";
          }, 4000);
        } else {
          this.$refs.toast.add({
            severity: "error",
            summary: "OOPS!",
            detail: "Intent not recognized.",
            life: 3000,
          });

          this.hasSpoken = false;

          this.playAudio("reject.mp3");
        }
      } catch (error) {
        console.error("Error uploading audio file:", error);
        this.hasSpoken = false;

        this.playAudio("error.mp3");
      } finally {
        // Clear canvas and stop animation
        this.stopRecording();
        this.audioChunks = [];
        this.isLoading = false;
      }
    },

    drawWaveform() {
      const canvas = this.$refs.canvas;
      const canvasCtx = canvas.getContext("2d");
      const WIDTH = canvas.width;
      const HEIGHT = canvas.height;
      const centerX = WIDTH / 2;
      const centerY = HEIGHT / 2;

      // Clear canvas
      canvasCtx.clearRect(0, 0, WIDTH, HEIGHT);

      // Reset canvas size (in case it's resized or reset)
      canvas.width = WIDTH;
      canvas.height = HEIGHT;

      this.analyser.getByteFrequencyData(this.dataArray);

      // Draw concentric circles
      for (let i = 0; i < this.analyser.frequencyBinCount; i++) {
        const gradient = canvasCtx.createRadialGradient(
          centerX,
          centerY,
          0,
          centerX,
          centerY,
          WIDTH / 2
        );
        gradient.addColorStop(
          0,
          `rgba(144, 238, 144, ${i / this.analyser.frequencyBinCount})` // Light Green
        );
        gradient.addColorStop(
          1,
          `rgba(173,255,47, ${i / this.analyser.frequencyBinCount})` // Dark Green
        );

        const radius = (this.dataArray[i] / 255) * (WIDTH / 2);
        canvasCtx.beginPath();
        canvasCtx.arc(centerX, centerY, radius, 0, Math.PI * 2);
        canvasCtx.strokeStyle = gradient;
        canvasCtx.lineWidth = 7;
        canvasCtx.stroke();
      }

      if (this.isRecording) {
        this.animationFrameId = requestAnimationFrame(this.drawWaveform);
      }
    },

    playAudio(fileName) {
      const audio = new Audio(`/speech/${fileName}`);
      audio.play();
    },

    playAudioSamples() {
      const audioSamples = ["welcome.mp3"];
      let index = 0;

      const playNext = () => {
        if (index < audioSamples.length) {
          const audio = new Audio(`/speech/${audioSamples[index]}`);
          audio.play();
          audio.onended = () => {
            index++;
            playNext();
          };
        }
      };
      playNext();
    },
  },
};
</script>

<style lang="scss">
body {
  margin: 0;
  padding: 0;
  font-family: museo-sans-rounded, sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  box-sizing: border-box;
}

.content {
  text-align: center;
  padding: 20px;
  padding-bottom: 100px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: calc(100vh - 270px);
  @media (max-width: 768px) {
    min-height: calc(100vh - 390px);
  }
}

.gradient-heading {
  font-size: 2.5rem;
  font-weight: bold;
  background: -webkit-linear-gradient(45deg, #544895, #9087c2);
  background: linear-gradient(45deg, #544895, #9087c2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 20px 0;
}

.green-mic {
  background-color: #28a745;
}

.audio-recording {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

  .status-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }

  .record-button-container {
    position: relative;
    width: 120px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 56px;

    .record-button {
      position: absolute;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background-color: #544895;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        font-size: 2rem;
        color: #fff;
      }

      &.recording {
        background-color: #28a745;
      }
    }

    .waveform {
      position: absolute;
      top: 0;
      left: 0;
      width: 120px;
      height: 120px;
      background-color: transparent;
    }
  }
  audio {
    margin-top: 10px;
    width: 100%;
    max-width: 600px;
  }
}

.footer {
  padding: 0.5rem;
  text-align: center;
  background-color: #f8f9fa;
  border-top: 2px solid #e9ecef;
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-top: auto;

  p {
    margin: 10px 0;
    color: #6c757d;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
  }

  a {
    color: #6c757d;
    text-decoration: underline;
  }
}
</style>
