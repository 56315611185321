<template>
  <Toolbar
    style="
      border-radius: 3rem;
      padding: 1rem 2rem 1.3rem 2rem;
      margin: 15px 15px;
    "
  >
    <template #start>
      <div class="flex align-items-center gap-16">
        <img
          src="https://d2dbymd6h65s0u.cloudfront.net/wp-content/uploads/2021/04/rezcomm-logo-2.svg.gzip"
          height="38"
          width="120"
          style="margin-right: 16px"
        />
      </div>
    </template>
  </Toolbar>
</template>

<script>
import Toolbar from "primevue/toolbar";

export default {
  name: "Header",
  components: {
    Toolbar,
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/variables.scss";
</style>
