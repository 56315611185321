import { createApp } from "vue";
import App from "./Index.vue";
import PrimeVue from "primevue/config";
import SlideUpDown from "vue3-slide-up-down";
import Dialog from "primevue/dialog";
import "primevue/resources/themes/aura-light-green/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import Toolbar from "primevue/toolbar";
import Button from "primevue/button";
import Message from "primevue/message";
import ToastService from "primevue/toastservice";
import Toast from "primevue/toast";
import ProgressSpinner from "primevue/progressspinner";

let app = createApp(App);
app.component("Dialog", Dialog);
app.component("Toolbar", Toolbar);
app.component("Button", Button);
app.component("Message", Message);
app.component("Toast", Toast);
app.component("ProgressSpinner", ProgressSpinner);
app.use(PrimeVue);
app.use(ToastService);

app.component("slide-up-down", SlideUpDown);

app.mount("#app");
